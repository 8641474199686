
import { defineComponent } from 'vue';
import { ProjectItem } from "@/models/ProjectItem";
import router from "@/router";
import ProjectItemStore from "@/stores/ProjectItemStore";

export default defineComponent({
  name: 'ProjectItemComponent',
  methods: {
    goBack(){
      router.push({name:'Projects'});
    },
    hasTechnique(item: ProjectItem){return item?.technique !== undefined},
    hasDrawing(item: ProjectItem){return item?.drawing !== undefined},
    hasCustomer(item: ProjectItem){return item?.customer !== undefined},
    hasByCarte(item: ProjectItem){return item?.byCarte !== undefined},
  },
  data(){
    return {
      detailItem: undefined as unknown as ProjectItem
    }
  },
  mounted(){
    window.scrollTo(0, 0);
    this.detailItem = ProjectItemStore.activeProjectItem as ProjectItem;
    
    let projectLink = document.getElementById('projectLink');
    projectLink!.classList.add('router-link-active','router-link-exact-active');
  },
  computed: {
    //hasTechnique(item: ProjectItem){return item?.technique !== ''},
    
  },
});
